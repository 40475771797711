import React from "react";
import "./App.scss";
import { Router, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles";
import { authenticationService, history } from "./auth/auth.service";
import { Homepage } from "./Homepage";
import Footer from "./project/Footer";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  // For some reason, this unused method is the only thing preventing
  // errors from occurring when trying to logout. When this method is
  // removed, there are errors when trying to logout.
  componentDidMount() {
    authenticationService.currentUser.subscribe((user) => {
      this.setState({ currentUser: user });
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <div className="app">
            <div className="app-container">
              <Route exact path="/:id" component={Homepage} />
              {/* <Route path="/login" component={LoginPage} />
              <Route path="/set-password" component={SetPasswordPage} />
              <Route path="/forgot-password" component={ForgotPasswordPage} />
              <Route path="/set-new-password" component={SetNewPasswordPage} /> */}
              <Footer />
            </div>
          </div>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
