export const styles = (theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: "12px 16px",
    },
  },
  label: {
    color: "#807c7c",
    margin: "0",
    marginRight: "8px",
    display: "inline-block",
    minWidth: "120px",
  },
  text: {
    margin: "0",
  },
  row: {
    display: "flex",
  },
  [theme.breakpoints.down("xs")]: {
    row: {
      flexDirection: "column",
    },
    text: {
      margin: "0",
    },
    label: {
      margin: "0 0 8px 0",
    },
  },
});
