import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";

const useStyles = makeStyles((theme) => ({
  noteLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "#f1f1f182",
    padding: "8px 12px",
    borderRadius: "4px",
    border: "1px solid #8080802b",
  },
  noteIcon: {
    color: "#b3b3b3c9",
    marginTop: "3px",
  },
  noteUpdated: {
    color: "#919192e6",
    fontSize: ".9rem",
    marginTop: "2px",
    whiteSpace: "nowrap",
  },
  noteText: {
    margin: "0 1rem",
  },
  noteList: {
    padding: "0",
    margin: "0",
    "& > div": {
      margin: "6px 0",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    noteLayout: {
      flexDirection: "column",
    },
    noteText: {
      margin: "12px 0 0 0",
    },
    noteIcon: {
      marginTop: "0",
    },
  },
}));

export const Note = ({ updated_at, note }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const updated = new Date(updated_at).toLocaleString("en-US", {
    dateStyle: "short",
    timeStyle: "short",
  });

  const Mobile = () => (
    <Box className={classes.noteLayout}>
      <div className={classes.row}>
        <EventNoteIcon fontSize="small" className={classes.noteIcon} />
        <Typography className={classes.noteUpdated}>{updated}</Typography>
      </div>
      <Typography className={classes.noteText}>{note}</Typography>
    </Box>
  );

  const Full = () => (
    <Box className={classes.noteLayout}>
      <EventNoteIcon fontSize="small" className={classes.noteIcon} />
      <Typography className={classes.noteText}>{note}</Typography>
      <Typography className={classes.noteUpdated}>{updated}</Typography>
    </Box>
  );

  return mobile ? <Mobile /> : <Full />;
};

export const Notes = ({ notes }) => {
  const classes = useStyles();
  return (
    <ul className={classes.noteList}>
      {notes.map((note) => (
        <Note key={note.id} {...note} />
      ))}
    </ul>
  );
};
