import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { styles } from "./projectStyles";
import { DateDisplay } from "./DateDisplay";
import { StatusLabel } from "./Status";
import { Notes } from "./Notes";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  container: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: "12px 16px",
    },
    border: "2px solid #d8d7dc9e",
    borderRadius: "6px",
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
    "& h2": {
      textTransform: "uppercase",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "12px",
      "& h2": {
        marginBottom: "16px",
      },
    },
  },
  stagesList: {
    padding: "0",
    margin: "0",
    "& > div": {
      margin: "0px 16px 32px 16px",
    },
  },
}));

export const JobStage = (props) => {
  const {
    description,
    status,
    date_started,
    date_completed,
    notes,
    name: task_name,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <Typography variant="h2">{task_name}</Typography>
        <StatusLabel status={status} />
      </div>
      {description && (
        <Typography component={"span"} className={classes.row}>
          <p className={classes.label}>description</p>
          <p className={classes.text}>{description}</p>
        </Typography>
      )}
      {date_started && <DateDisplay date={date_started} label="started" />}
      {date_completed && (
        <DateDisplay date={date_completed} label="completed" />
      )}
      {notes && notes.length > 0 && (
        <div className={classes.row}>
          <Typography>
            <span className={classes.label}>notes</span>
          </Typography>
          <Notes notes={notes} />
        </div>
      )}
    </div>
  );
};

export const JobStages = ({ stages }) => {
  const classes = useStyles();
  return (
    <ul className={classes.stagesList}>
      {stages.map((stage) => (
        <JobStage key={stage.id} {...stage} />
      ))}
    </ul>
  );
};
