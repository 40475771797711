import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ProjectOverview } from "./Overview";
import { JobStages } from "./JobStages";
import { ProjectPaper } from "./ProjectPaper";
import { Sorting, sort, ASC } from "./Sorting";

const useStyles = makeStyles({
  projectContainer: {
    margin: "12px 0",
  },
  stageLabelContainer: {
    display: "flex",
    alignItems: "center",
    padding: "32px 0 16px 0",
    justifyContent: "space-between",
    margin: "0 16px",
  },
  noProjectsContainer: {
    border: "dashed black",
    borderRadius: "3px",
    padding: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tasksHeader: {
    fontSize: "1.4rem",
    fontWeight: 600,
    color: "#636363",
  },
});

export const NoProjects = () => {
  const classes = useStyles();
  return (
    <div className={classes.noProjectsContainer}>
      <Typography variant="h1">You don't have any projects yet</Typography>
    </div>
  );
};

export const ProjectDisplay = ({ project }) => {
  const classes = useStyles();
  const [stages, setStages] = useState(project.tasks);
  const [orderBy, setOrderBy] = useState({ field: "status", order: ASC });

  useEffect(() => {
    setStages(sort(stages, orderBy.field, orderBy.order));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  return project ? (
    <div className={classes.projectContainer}>
      <ProjectPaper>
        <ProjectOverview {...project} />
        <div className={classes.stagesContainer}>
          <div className={classes.stageLabelContainer}>
            <Typography variant="h2" className={classes.tasksHeader}>
              Project Tasks
            </Typography>
            <Sorting order={orderBy} setOrder={setOrderBy} />
          </div>
          <JobStages stages={stages} />
        </div>
      </ProjectPaper>
    </div>
  ) : (
    <NoProjects />
  );
};
