import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleIcon from "@material-ui/icons/Schedule";

const useStyles = makeStyles({
  layout: {
    display: "inline-flex",
    alignItems: "center",
    "& svg": {
      marginRight: "4px",
    },
    backgroundColor: "white",
    padding: "8px 12px",
    borderRadius: "4px",
  },
  completed: {
    color: "green",
    backgroundColor: "#a4f9a461",
    border: "1px solid green",
  },
  inProgress: {
    color: "#a29e04",
    backgroundColor: "#d0d0092e",
    border: "1px solid #a29e04",
  },
  notStarted: {
    color: "grey",
    backgroundColor: "#d2d2d27a",
    border: "1px solid grey",
  },
});

const InProgress = ({ classes }) => (
  <Box className={`${classes.layout} ${classes.inProgress}`}>
    <ScheduleIcon fontSize="small" />
    <Typography>in progress</Typography>
  </Box>
);

const Completed = ({ classes }) => (
  <Box className={`${classes.layout} ${classes.completed}`}>
    <CheckIcon fontSize="small" />
    <Typography>completed</Typography>
  </Box>
);

const NotStarted = ({ classes }) => (
  <Box className={`${classes.layout} ${classes.notStarted}`}>
    <CloseIcon fontSize="small" />
    <Typography>not started</Typography>
  </Box>
);

export const StatusLabel = ({ status }) => {
  const classes = useStyles();
  const values = ["in-progress", "completed", "not-started"];
  const componentForStatus = {
    "in-progress": <InProgress classes={classes} />,
    completed: <Completed classes={classes} />,
    "not-started": <NotStarted classes={classes} />,
  };

  if (!values.includes(status)) return null;

  return componentForStatus[status];
};
