import React, { useState } from "react";
import { Button, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export const DESC = "descending";
export const ASC = "ascending";

const SORT_OPTIONS = [
  { name: "status", label: "Status", order: ASC },
  { name: "status", label: "Status", order: DESC },
  { name: "updated_at", label: "Last Updated", order: ASC },
  { name: "updated_at", label: "Last Updated", order: DESC },
];

const getIndexFromOrder = (order) => {
  if (!order) {
    return;
  }

  const index = SORT_OPTIONS.findIndex(
    (option) => order.field === option.name && order.order === option.order
  );

  if (index === -1) {
    // Should not happen
    throw new Error(`Could not match order ${order}`);
  }
  return index;
};

export const Sorting = ({ order, setOrder }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(getIndexFromOrder(order));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelected(index);
    setAnchorEl(null);
    const option = SORT_OPTIONS[index];
    setOrder({ field: option.name, order: option.order });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Sort Tasks
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {SORT_OPTIONS.map((option, index) => {
          return (
            <MenuItem
              key={index}
              onClick={(event) => handleMenuItemClick(event, index)}
              selected={index === selected}
            >
              {option.label}
              <ListItemIcon>
                {option.order === ASC ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </ListItemIcon>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const compareGeneric = (a, b, order) => {
  if (a === b) {
    return 0;
  } else if (order === DESC && a < b) {
    return 1;
  } else if (order === ASC && a < b) {
    return -1;
  } else if (order === DESC && a > b) {
    return -1;
  }
  return 1;
};

const compareStatus = (a, b, order) => {
  if (a === b) {
    return 0;
  }
  const statusOrder = ["not-started", "in-progress", "completed"];
  if (statusOrder.indexOf(a) > statusOrder.indexOf(b)) {
    if (order === ASC) {
      return 1;
    }
    return -1;
  } else if (statusOrder.indexOf(a) < statusOrder.indexOf(b)) {
    if (order === ASC) {
      return -1;
    }
    return 1;
  }
};

export const sort = (stages, field, order) => {
  const func = field === "status" ? compareStatus : compareGeneric;
  return [...stages].sort((a, b) => func(a[field], b[field], order));
};
