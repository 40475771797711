import { BehaviorSubject } from "rxjs";

import { createBrowserHistory } from "history";

const userJson = localStorage.getItem("currentUser");
const user = userJson !== null ? JSON.parse(userJson) : null;

const currentUserSubject = new BehaviorSubject(user);

export interface AuthenticatedUser {
  access_token: string;
  id_token: string;
  refresh_token: string;
  expires: number;
  group: string;
  username: string;
}

export const authenticationService = {
  login,
  logout,
  setPermanentPassword,
  forgotPassword,
  setNewPassword,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

export const history = createBrowserHistory();

function login(username: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/signin`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      // Only set the currentUser if response was authenticated
      if (res.key === "authenticated") {
        localStorage.setItem("currentUser", JSON.stringify(res));
        currentUserSubject.next(res);
      }

      return res;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

/**
 * Sets a permanent password for a user since a temp password is first assigned
 */
function setPermanentPassword(
  session: string,
  userId: string,
  password1: string,
  password2: string
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ session, password1, password2, username: userId }),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/set-password`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      // Only set the currentUser if response was authenticated
      if (res.key === "authenticated") {
        localStorage.setItem("currentUser", JSON.stringify(res));
        currentUserSubject.next(res);
      }

      return res;
    });
}

function forgotPassword(email: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/forgot-password`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      return res;
    });
}

function setNewPassword(
  code: string,
  password1: string,
  password2: string,
  email: string
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code, password1, password2, email }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/set-new-password`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      return res;
    });
}

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.id_token) {
    // return { Authorization: `Bearer ${currentUser.access_token}` };
    return { Authorization: `Bearer ${currentUser.id_token}` }; // use id_token instead for now
  } else {
    return {};
  }
}

export function handleResponse(response: Response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        window.location.reload(true);
      }

      const error = (data && data.key && data) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
