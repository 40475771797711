import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  logoContainer: {
    color: "#31314ec4",
    textDecoration: "none",
    marginTop: "16px",
    marginBottom: "16px",
  },
  label: {
    margin: 0,
    fontStyle: "italic",
  },
  logo: {
    margin: 0,
    fontFamily: "'Merriweather', serif",
    fontStyle: "italic",
    fontSize: "24px",
    fontWeight: 900,
    marginLeft: "16px",
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <a
      className={classes.logoContainer}
      href={`${process.env.REACT_APP_PF_URL}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className={classes.label}>powered by</p>
      <p className={classes.logo}>project flare</p>
    </a>
  );
};

export default Footer;
