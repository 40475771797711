import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Notes } from "./Notes";
import { styles } from "./projectStyles";
import { DateDisplay } from "./DateDisplay";

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  headerText: {
    marginBottom: "16px",
    fontWeight: "400",
    textTransform: "uppercase",
  },
}));

export const ProjectOverview = ({
  name,
  description,
  date_started,
  date_completed,
  notes,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.layout}>
      <Typography className={classes.headerText} variant="h1">
        {name}
      </Typography>
      {description && (
        <Typography component={"span"} className={classes.row}>
          <p className={classes.label}>description</p>
          <p className={classes.text}>{description}</p>
        </Typography>
      )}
      {date_started && <DateDisplay date={date_started} label="started" />}
      {date_completed && (
        <DateDisplay date={date_completed} label="completed" />
      )}
      {notes && notes.length > 0 && (
        <div className={classes.row}>
          <Typography>
            <span className={classes.label}>notes</span>
          </Typography>
          <Notes notes={notes} />
        </div>
      )}
    </div>
  );
};
