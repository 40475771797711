import React from "react";

import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: "16px",
    borderRadius: "12px"
  },
});

export const ProjectPaper = (props) => {
  const classes = useStyles();
  return <Paper className={classes.root} elevation={0} {...props} />;
};
