import React from "react";
import { ProjectDisplay } from "./ProjectDisplay";
import { makeStyles, CircularProgress, Typography } from "@material-ui/core";
import { useFetch } from "./FetchProject";
import { ProjectPaper } from "./ProjectPaper";

const useStyles = makeStyles({
  progressContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "64px 0",
  },
  errorText: {
    fontSize: "20px",
  },
});

const ErrorDisplay = () => {
  const classes = useStyles();

  return (
    <ProjectPaper>
      <Typography className={classes.errorText}>
        Oh no! There was a problem loading your project. Please try again later.
      </Typography>
    </ProjectPaper>
  );
};

export const Project = (props) => {
  const { project, loading, hasError } = useFetch(props.project);
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress color="secondary" />
      </div>
    );
  } else if (hasError) {
    return <ErrorDisplay />;
  } else if (project) {
    return <ProjectDisplay project={project} />;
  }
  return null;
};
