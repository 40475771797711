import React from "react";
import { makeStyles } from "@material-ui/core";
import { useFetch } from "./project/FetchProject";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    minHeight: "110px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    maxWidth: "200px",
  },
  [theme.breakpoints.down("xs")]: {
    logo: {
      maxWidth: "135px",
    },
    user: {
      fontSize: ".65rem",
    },
  },
}));

export const Navbar = ({ projectId }) => {
  const classes = useStyles();

  // Workaround for fetching team id to use for logo url. Should ideally
  // just be fetching project once and then passing team_id to this component as child prop
  const { project } = useFetch(projectId);

  return (
    <div className={classes.header}>
      {project && (
        <img
          className={classes.logo}
          src={`https://projectflare.nyc3.cdn.digitaloceanspaces.com/${project.team_id}/logo.png`}
          alt="logo"
        />
      )}
    </div>
  );
};
