import { createMuiTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: "1.6rem",
      [breakpoints.down("xs")]: {
        fontSize: "1.1rem",
      },
    },
    h2: {
      fontSize: "1.3rem",
      [breakpoints.down("xs")]: {
        fontSize: "1.1rem",
      },
    },
    h3: {
      fontSize: "1.2rem",
    },
  },
  palette: {
    primary: {
      main: "rgb(51, 51, 51)",
    },
    secondary: {
      main: "#34fd00",
    },
    background: {
      default: "white",
    },
  },
});
