import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { styles } from "./projectStyles";

const useStyles = makeStyles(styles);

export const DateDisplay = ({ date, label }) => {
  const classes = useStyles();
  const d = new Date(date);
  return (
    <Typography component={"span"} className={classes.row}>
      <p className={classes.label}>{label}</p>
      <p className={classes.text}>
        {d.toLocaleDateString("en-US", { timeZone: "UTC" })}
      </p>
    </Typography>
  );
};
