import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Navbar } from "./Navbar";
import { Project } from "./project/Project";
import { useParams } from "react-router";

const useStyles = makeStyles({
  homepageContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  projectSelectContainer: {
    margin: "16px 0",
  },
});

export const Homepage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [project, setProject] = useState();

  useEffect(() => {
    setProject(id);
  }, [project, id]);

  return project ? (
    <div className={classes.homepageContainer}>
      <Navbar projectId={project} />
      <Project project={project} />
    </div>
  ) : (
    <p>loading...</p>
  );
};
