import { useState, useEffect } from "react";

import * as Sentry from "@sentry/react";

export function useFetch(projectId) {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/tree`;

  useEffect(() => {
    setLoading(true);
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setLoading(false);
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then((json) => setProject(json))
      .catch((err) => {
        setHasError(true);
        setLoading(false);
        err.text().then((errorMessage) => {
          Sentry.captureException(errorMessage);
        });
      });
  }, [url]);

  return { project: project, loading: loading, hasError: hasError };
}
